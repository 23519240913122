import React from "react";
import styles from "./Spotlight.module.scss"
import "./spot.scss"
export const CompanySpotlight = () => {
    document.documentElement.classList.add('admin')
    return(
        <div className={styles.page__container}>
            <h1 className={styles.page__title}>Spotlight</h1>
            <div className={styles.page__content} id="spotlight_tabs">

                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                           role="tab" aria-controls="pills-home" aria-selected="true">Engagement </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                           role="tab" aria-controls="pills-profile" aria-selected="false">Well Bein</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
                           role="tab" aria-controls="pills-contact" aria-selected="false">KPI</a>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab">


                        <div className="spotlight_company">
                            <h4 className="spotlight_company-title">Company Spotlight</h4>
                            <div className="spotlight_company-scores">
                                Actively not engaged: <span className="scores_value">3 score</span>
                            </div>
                        </div>

                        <div className="spotlight_company-box_container">
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon autonomie"></div>
                                <p className="spotlight_company-box_name">Autonomie</p>

                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={7} style={{ width: '70%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '65%'}}>7</span>
                                    <span className="progress_value-item">10</span>
                                </div>

                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon sens"></div>
                                <p className="spotlight_company-box_name">Sens</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={4} style={{ width: '40%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '35%'}}>4</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon progression"></div>
                                <p className="spotlight_company-box_name">Progression</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={9} style={{ width: '90%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '85%'}}>9</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon reconnaissance"></div>
                                <p className="spotlight_company-box_name">Reconnaissance</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={3} style={{ width: '30%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '25%'}}>3</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon connexion"></div>
                                <p className="spotlight_company-box_name">Connexion</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={5} style={{ width: '50%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '45%'}}>5</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>

                        </div>



                        <div className="spotlight_departments-content">
                            <h4 className="spotlight_company-title">Departments</h4>


                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck1" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck1">All employees</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck2" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck2">HR
                                        2</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck3" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck3">Managers</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck4" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck4">Sales</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck5" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck5">Developers</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck6" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck6">Designers</label>
                                </div>
                            </div>
                        </div>

                        <div className="spotlight_card-container">
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Autonomie</p>
                                    <div className="spotlight_card-progress">7.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">70 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon autonomie"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Sens</p>
                                    <div className="spotlight_card-progress">9.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">90 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon sens"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Progression</p>
                                    <div className="spotlight_card-progress">6.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">30 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon progression"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Reconnaissance</p>
                                    <div className="spotlight_card-progress">7.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">60 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon reconnaissance"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Connexion</p>
                                    <div className="spotlight_card-progress">5.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">20 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon connexion"></div>
                                </div>
                            </div>
                        </div>

                        <div className="spotlight_table-container">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Full name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Problems</th>
                                    <th scope="col">Progress</th>
                                    <th scope="col"> </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>





                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                         aria-labelledby="pills-profile-tab">jkjkjkjkjkjkjkjkjk
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                         aria-labelledby="pills-contact-tab">xcxcxcxcxcxcxc
                    </div>
                </div>
            </div>
        </div>
    )
}
