import React from "react";
import Modal from 'react-modal';
import './Modal.scss'
export const MigrateModal  = (props) => {
    const customStyles = {
        content: {
            padding: '30px',
            maxWidth: '700px',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background:'#FFFFFF',
            borderRadius:'12px',
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)'
        },
        overlay: {zIndex: 1000}
    };
        return (
            <Modal
                isOpen={props.migrateModalIsOpen}
                onRequestClose={props.closeMigrateModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="modal_inside">
                    <div className="modal_header"  onClick={props.closeMigrateModal}>
                        <h4 className="modal_title">{props.data?.title}</h4>
                        <div className="modal_close"></div>
                        {/*<img className="closeModalImg" src={CloseModal} alt=""/>*/}
                        {/*<div className="closeWord">Close</div>*/}
                    </div>

                    <div className="modal_body">
                        <div className="modal_main_text">{props.data?.text}</div>
                        <div className="modal_desciption">{props.data?.description}</div>

                        <div className="modal_btn_container">
                            <div className="modal_btn" onClick={props.closeMigrateModal}>{props.data?.button_info1}</div>
                            <div className="modal_btn active" onClick={()=>props.onSuccess()}>{props.data?.button_info2}</div>
                        </div>
                    </div>


                    {/*<div className="modalLi" dangerouslySetInnerHTML={{__html:migrateModalInfo?.description}}/>*/}
                    {/*<div className="modalBtn">{props.data?.button_info}</div>*/}
                </div>
            </Modal>
    )
}
