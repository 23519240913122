import React from "react";
import styles from './Hr.module.scss'

export const CompanyHr = () => {
    return (
        <div className={styles.page__container}>
            <h1 className={styles.page__title}>Departments</h1>
            <div className={styles.page__users}>

                <div className={styles.page__users_item}>
                    <span className={styles.user__item_dots}></span>
                    <div className={styles.user__item_img__container}>
                        <img src={require('../../../assets/images/user_ann.png')} alt=""/>
                    </div>
                    <h3 className={styles.user__item_name}>Ann Jones</h3>
                    <p className={styles.user__item_email}>annjones@gmail.com</p>
                    <h2 className={styles.user__item_position}>HEAD HR</h2>
                </div>

                <div className={styles.page__users_item}>
                    <span className={styles.user__item_dots}></span>
                    <div className={styles.user__item_img__container}>
                        <img src={require('../../../assets/images/user_lily.png')} alt=""/>
                    </div>
                    <h3 className={styles.user__item_name}>Lily Davis</h3>
                    <p className={styles.user__item_email}>lilydavis@gmail.com</p>
                    <h2 className={styles.user__item_position}>COORDINATOR</h2>
                </div>

                <div className={styles.page__users_item}>
                    <span className={styles.user__item_dots}></span>
                    <div className={styles.user__item_img__container}>
                        <img src={require('../../../assets/images/user_evan.png')} alt=""/>
                    </div>
                    <h3 className={styles.user__item_name}>Evan Smith</h3>
                    <p className={styles.user__item_email}>evansmith@gmail.com</p>
                    <h2 className={styles.user__item_position}>RECRUITER</h2>
                </div>

                <div className={styles.page__users_item}>
                    <span className={styles.user__item_dots}></span>
                    <div className={styles.user__item_img__container}>
                        <img src={require('../../../assets/images/user_brad.png')} alt=""/>
                    </div>
                    <h3 className={styles.user__item_name}>Brad Jones</h3>
                    <p className={styles.user__item_email}>bradjones@gmail.com</p>
                    <h2 className={styles.user__item_position}>JUNIOR HR</h2>
                </div>
            </div>

        </div>
    )
}
