import React, {useState} from "react";
import styles from './Accoint.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";


export const CompanyCreateDepartment = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [file, setFile] = useState(null);
    const setMyValue = (e) => {
        console.log(e, 'gggg')
    };
    const { register, handleSubmit, formState: { errors }, } = useForm()
    const onSubmit = async (data) => {
        console.log(data, 'send')
        try {
            let res = await axiosInstance.post(`departments`, data)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_departments')
            }
        } catch (error) {
            // setError(error);
        }
    }
    const onFileChange = (e) => {
        const files = e.currentTarget.files
        if(files) {
            setFile(files[0]);
        }
        console.log(file, 'file')
    }
    return(
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Departments</h1>
            <div className={styles.page__data_block}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" onChange={onFileChange} />
                        </div>
                    </div>
                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Department name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Email address</label>
                                <Input input={{
                                    placeholder: "companyname@gmail.com",
                                    name: "email",
                                    type: "email",
                                    ...register("email", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.email  && <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Activity</label>
                                <select className="form-control" id="activity" {...register("activity")} onChange={(e) => setMyValue(e.target.value)}
                                        defaultValue={'1'}>
                                    <option selected disabled value='1'>activity</option>
                                    <option value='2'>activity</option>
                                    <option value='3'>activity</option>
                                    <option value='4'>activity</option>
                                    <option value='5'>activity</option>
                                </select>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                <Input input={{
                                    placeholder: "+87 5469 554 78",
                                    name: "phone",
                                    type: "number",
                                    ...register("phone", { required: true })
                                }}
                                       className="form-control"
                                />
                            </div>
                        </div>
                        <div className={styles.page__done_container}>
                            <button className={styles.page__done_btn} type="submit">Done</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
