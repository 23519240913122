import React, {useEffect, useState} from "react";
import styles from './SuperCompanies.module.scss'
import {useNavigate} from "react-router-dom";

import axiosInstance from "../../../helpers/api";
import Constants from '../../../config';

export const SuperCompanies = () => {
    document.documentElement.classList.add('super_admin')
    const baseURL = Constants.baseUrl
    const navigate=useNavigate();
    const [companies, setCompanies] = useState([])
    useEffect(() => {
        getCompanies();
    }, [])
    const getCompanies = async () => {
        try {
            let res = await axiosInstance.get(`admin/companies`)
            setCompanies(res.data.data)
        } catch (error) {
            // setError(error);
        }
    }
    const editCompany = (data) => {
        navigate('/super_edit_company', {state: {data}})
    }
    const handleChangeFile = async (e) => {
        const files = e.currentTarget.files
        if (files) {
            let formData = new FormData();
            formData.append('file', files[0]);
            try {
                let res = await axiosInstance.post(`admin/companies/import`, formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
                })
                if(res.status === 201 || res.status === 200) {
                    console.log('uploaded ok')
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <div className={`${styles.companies_container} m-0`}>
            <div className={styles.companies_head}>
                <h3 className={styles.companies__title}>Company</h3>
                <div className="d-flex">
                    <a href={`${baseURL}companies/sample-csv`}>
                        <div className={styles.companies_head_btn}>Download CSV</div>
                    </a>
                    <div className={styles.companies_head_btn}>
                        <input type="file" onChange={handleChangeFile}/>
                        Import a company</div>
                </div>
            </div>

            {
                companies.length > 0 ?
                <div className={styles.companies__main_container}>
                    { companies.map((t, i) => {
                        return(
                            <div key={i} className={styles.companies__users_item} onClick={() => editCompany(t)}>
                                <div className={styles.user__item_img__container}>
                                    {t.logo ?
                                    <img
                                        src={t.logo}
                                        alt={t.name}
                                    /> : <div className={styles.user__item_empty}></div>
                                    }
                                </div>
                                <h2 className={styles.user__item_position}>{t.name}</h2>
                            </div>
                        )
                    })}
                    <div className={styles.companies__users_item} onClick={() => { navigate('/super_create_company')}}>
                        <div className={styles.user_team__icon}>
                        </div>
                        <h2 className={styles.user_team}>Create a company</h2>
                    </div>
                </div>
                    :
                    <div className={styles.companies__main_content}>
                        <h4 className={styles.companies__subtitle}>You don’t have company yet</h4>
                        <div className={styles.btn__container}>
                            <button className={styles.main_btn} onClick={() => { navigate('/super_create_company')}}><i className={styles.main_btn__edit_icon}></i>Create a company</button>
                            <button className={styles.main_btn} onClick={() => { navigate('/super_import_company')}}><i className={styles.main_btn__paper_icon}></i>Import a company</button>
                        </div>
                    </div>
            }


        </div>
    )
}
