import Constants from '../config';
import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';

const getDataTokken = async () => {
  try {
    const value = await localStorage.getItem('token');
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log(e);
  }
};

const axiosInstance = axios.create({
  baseURL: Constants.baseUrl,
  timeout: 10000000,
});


axiosInstance.interceptors.request.use(
  async config => {
    let token = await getDataTokken();
      config.headers["Access-Control-Allow-Origin"] = "*";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      if(config.headers["Content-Type"] === undefined) {
          config.headers[`Content-Type`] = 'application/json';
      } else {
          config.headers[`Content-Type`] = 'multipart/form-data';
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
