import React from "react";
import { Navigate } from 'react-router-dom';

const RoleGuard = ({children, allowedRoles, userRole}) => {
    if(!allowedRoles.includes(userRole)) {
        return <Navigate to="/login" replace />;
    }
    return children;
}

export default RoleGuard;
