import React, {useState} from "react";
import styles from './SuperCreateAdmin.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate } from "react-router-dom";

export const SuperCreateAdmin = () => {
    document.documentElement.classList.add('super_admin')
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState('');
    const { register, setError, handleSubmit, formState: { errors } } = useForm()
    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append('email', data.email);
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('phone', data.phone);
        formData.append('username', data.username);
        formData.append('password', data.password);
        formData.append('role', 'company_admin');
        selectedImage && formData.append('image', selectedImage);
        try {
            let res = await axiosInstance.post(`admin/users`, formData);
            if (res.status === 201 || res.status === 200) {
                navigate(-1);
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    const cancelCreate = () => {
        navigate(-1)
    }
    return(
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Admin</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*block*/}
                <div className={styles.page__data_block}>
                    <div className={styles.page__data_left}>
                        <p className={styles.page__data_sub}>Admin profile</p>
                        <div className={styles.page__data_img__container}>
                            <div className={styles.page__data_img__icon}>
                                <input type="file" accept="image/*" onChange={onFileChange} />
                            </div>
                            {selectedImage && (
                                <div className={styles.page__data_img_selected}>
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>First name</label>
                                <Input input={{
                                    placeholder: "First name",
                                    name: "first_name",
                                    type: "text",
                                    ...register("first_name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.first_name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Last name</label>
                                <Input input={{
                                    placeholder: "Last name",
                                    name: "last_name",
                                    type: "text",
                                    ...register("last_name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.last_name && <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>

                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Email address</label>
                                <Input input={{
                                    placeholder: "companyname@gmail.com",
                                    name: "email",
                                    type: "email",
                                    ...register("email", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.email?.message ?
                                    <span className='textDanger'>{errors.email.message}</span> :
                                    errors.email &&
                                    <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                <Input input={{
                                    placeholder: "+87 5469 554 78",
                                    name: "phone",
                                    type: "number",
                                    ...register("phone", { required: false })
                                }}
                                       className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.page__data_block}>
                    <div className={styles.page__data_left}>
                        <p className={styles.page__subtitle}>Secure information</p>
                    </div>
                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Login</label>
                                <Input input={{
                                    placeholder: "Login",
                                    name: "username",
                                    type: "text",
                                    ...register("username", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.username?.message ?
                                    <span className='textDanger'>{errors.username.message}</span> :
                                    errors.username &&
                                    <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Password</label>
                                <Input input={{
                                    placeholder: "Password",
                                    name: "password",
                                    type: "password",
                                    ...register("password", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.password  && <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.page__done_container}>
                    <button className={styles.page__cancel_btn} onClick={() => cancelCreate()}>Cancel</button>
                    <button className={styles.page__done_btn} type="submit">Create</button>
                </div>

            </form>
        </div>
    )
}
