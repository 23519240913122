import React, {useState} from "react";
import styles from './LogIn.module.scss';
import axios from "axios";
import config from "../../config";
import {useNavigate} from 'react-router-dom'
import Input from '../../UI/Input/Input'
import useInput from "../../hooks/useInput";
import useValidation from '../../hooks/useValidation'
import EyeImage from '../../assets/icons/EyeImage.png';
import ClosedEye from '../../assets/icons/ClosedEye.png';
// import TextField from '@mui/material/TextField'
// import {styled} from '@mui/material/styles';

export const LogIn = ({changeRole}) => {
    const navigate = useNavigate()
    const {isNotEmpty, isEmail} = useValidation();
    const [error, setError] = useState(null);
    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
    } = useInput(isNotEmpty);
    // let role = 'super_admin'
    // document.documentElement.classList.add(role);


    let emailMessage = null;
    if (!email) {
        emailMessage = "Email is required";
    } else if (!emailIsValid) {
        emailMessage = "Invalid email";
    }

    let formIsValid = false;
    if (emailIsValid
        && passwordIsValid
    ) {
        formIsValid = true;
    }
    var classList = document.documentElement.classList;
    while (classList.length > 0) {
        classList.remove(classList.item(0));
    }
    document.documentElement.classList.add('admin')
    const values = {
        email,
        password,
    }

    let postLogin = async (values) => {
        let formData = {
            identifier: values.email,
            password: values.password,
        }

        try {
            let response = await axios.post(`${config.baseUrl}login`, formData);
            // sessionStorage.setItem('token', response.data.token);
            // props.setAccessToken(sessionStorage.getItem('token'));
            // sessionStorage.setItem('role', response.data.role);
            // props.setRole(sessionStorage.getItem('role'));
            if (response.data.role === 'superadmin') {
                let token = response.data.token
                localStorage.setItem('userRole', 'superadmin')
                localStorage.setItem('token', token)
                console.log(response.data, 'data')
                var aClassList = document.documentElement.classList;
                while (aClassList.length > 0) {
                    aClassList.remove(aClassList.item(0));
                }
                document.documentElement.classList.add("super_admin");
                changeRole('superadmin')
                navigate('/super_companies');
            }
            if(response.data.role ==="company_admin"){
                let token = response.data.token
                localStorage.setItem('userRole', 'company_admin')
                localStorage.setItem('token', token)
                console.log(response.data, 'data')
                var classList = document.documentElement.classList;
                while (classList.length > 0) {
                    classList.remove(classList.item(0));
                }
                document.documentElement.classList.add("admin");
                changeRole('company_admin')
                navigate('/admin_departments');
            }
            if(response.data.role ==="manager"){
                localStorage.setItem('userRole', 'manager')
                var mclassList = document.documentElement.classList;
                while (mclassList.length > 0) {
                    mclassList.remove(mclassList.item(0));
                }
                document.documentElement.classList.add("manager");
                changeRole('manager')
                navigate('/manager');

            }
        } catch (error) {
            setError(error);

        }
    }


    const submitHandler = event => {
        setError();
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        postLogin(values);
        resetEmail();
        resetPassword();

    }
    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }
    ///////////////////////////////////////////
    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: '#373737',
    //         fontWeight: 400
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             width: '100%',
    //             border: '2px solid #B8B8B8',
    //         },
    //         borderRadius: 26,
    //         width: 500
    //     },
    //
    // });

    return (
        <div className={styles.sign_container}>

            <div className={styles.sign_content}>
                <div className={styles.sign_title}>
                    Login
                </div>

                <form className={styles.sign_form} onSubmit={submitHandler}>
                    {/*<div className={styles.sign_input_item}>*/}
                        {/*<TextField className={styles.sign_input} id="outlined-basic" label="Name" variant="outlined" />*/}
                        {/*<CssTextField label="Name" id="custom-css-outlined-input"/>*/}
                    {/*</div>*/}
                    {/*<div className={styles.sign_input_item}>*/}
                        {/*<TextField className={styles.sign_input} id="outlined-basic" label="Name" variant="outlined" />*/}
                        {/*<CssTextField label="Email" type={email} id="custom-css-outlined-input"/>*/}
                    {/*</div>*/}


                    {/*<div className={styles.sign_input_item}>*/}
                        {/*<TextField className={styles.sign_input} id="outlined-basic" label="Name" variant="outlined" />*/}
                        {/*<CssTextField label="Location" id="custom-css-outlined-input"/>*/}
                    {/*</div>*/}


                    <div>
                        <Input
                            hasError={emailHasError}
                            errorText={emailMessage}
                            input={{
                                value: email,
                                placeholder: "Email",
                                type: "email",
                                onChange: emailChangeHandler,
                                onBlur: emailBlurHandler
                            }}
                        />
                    </div>
                    <div>
                        <Input
                            hasError={passwordHasError}
                            errorText="Please enter valid password."
                            onClick={passwordShowHandler}
                            image={passwordShow ? EyeImage : ClosedEye}
                            input={{
                                width: "100%",
                                value: password,
                                placeholder: "Password",
                                type: passwordShow ? "text" : "password",
                                onChange: passwordChangeHandler,
                                onBlur: passwordBlurHandler,
                                onKeyPress: handleKeyPress
                            }}
                        />
                    </div>



                    {/*<div className={s.sign_input_item}>*/}
                    {/*    <label htmlFor="email">Email</label>*/}
                    {/*    <input*/}
                    {/*        type="email"*/}
                    {/*        placeholder="Email"*/}
                    {/*        className="form-control"*/}
                    {/*        id="exampleInputEmail1"*/}
                    {/*        aria-describedby="emailHelp"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={s.sign_input_item}>*/}
                    {/*    <label htmlFor="password">Password</label>*/}
                    {/*    <input*/}
                    {/*        type="password"*/}
                    {/*        placeholder="Password"*/}
                    {/*        className="form-control"*/}
                    {/*        id="exampleInputPassword"*/}
                    {/*        aria-describedby="passwordHelp"*/}
                    {/*    />*/}
                    {/*</div>*/}


                    <div className={styles.sign_btn_container}>
                        <button className={styles.btn_fill} type={"submit"}>Login</button>
                    </div>
                </form>
                <div className="textDanger">
                    {error && <span>{error.response.data.message}</span>}
                </div>

            </div>
            {/*<div className={styles.sign_text}>This site is protected by <span className={styles.sign_text_link}>*/}
            {/*    reCAPTCHA</span> and the <span className={styles.sign_text_link}>Google Privacy Policy</span> and <span*/}
            {/*    className={styles.main_text_link}>Terms of Service</span> apply.*/}
            {/*</div>*/}

        </div>
    )
}
