import React, {useEffect, useState} from "react";
import styles from './SuperEditCompany.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate, useLocation} from "react-router-dom";
import {MigrateModal} from "../../../UI/Modal/Modal";

export const SuperEditCompany = (props) => {
    document.documentElement.classList.add('super_admin')
    const location = useLocation()
    const [admins, setAdmins] = useState([])
    const [checkedAdmins, setCheckedAdmins] = useState([])

    const [createdAdmins, setCreatedAdmins] = useState([])

    const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
    const navigate=useNavigate();
    const [editId, setEditId] = useState([]);
    const [deleteCompanyId, setDeleteCompanyId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [changedImg, setChangedImg] = useState(null);
    const { register,setError, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: location.state.data ? {
            name: location.state.data.name,
            email: location.state.data.email,
            phone: location.state.data.phone,
            location: location.state.data.location,

            turnover: location.state.data.turnover,
            absenteeism: location.state.data.absenteeism,
            sales: location.state.data.sales,
            margins: location.state.data.margins,
        }
        : undefined,
    })
    useEffect(() => {
        setEditId(location.state.data.id, [location.state.data.id])
        setSelectedImage(location.state.data.logo,[ location.state.data.logo])
        getCompanyData(location.state.data.id)
        getAdmins();
    })
    const getCompanyData = async (id) => {
        try {
            let res = await axiosInstance.get(`admin/companies/${id}`)
            if(res.status === 200 || res.status === 201 ) {
                let company_data = res.data.data
                setAdmins(company_data.admins)
            }
        } catch (error) {
            // setError(error);
        }
    }
    const getAdmins = async () => {
        try {
            let res = await axiosInstance.get(`admin/users`)
            setCreatedAdmins(res.data)
        } catch (error) {
            // setError(error);
        }
    }

    const deleteCompany = () => {
        setMigrateModalIsOpen(true);
        setDeleteCompanyId(editId)
    }
    const newAdmin = () => {
        navigate('/super_create_admin')
    };
    const newManager = () => {
        console.log('manager')
    };
    const deleteAdmin = (id) => {
        setMigrateModalIsOpen(true);
    }
    const editAdmin = (data) => {
        let company = location.state.data
        navigate('/super_edit_admin', {state: {data, company}})
    }
    const delCompany = async () => {
        try {
            let res = await axiosInstance.delete(`admin/companies/${deleteCompanyId}`)
            if(res.status === 200 || res.status === 201 ) {
                closeMigrateModal()
                navigate('/super_companies')
            }
        } catch (error) {
            // setError(error);
        }
    }
    function closeMigrateModal(){
        setMigrateModalIsOpen(false);
    }
    const handleAdmins = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setCheckedAdmins(selectedValues);
    }
    const onSubmit = async (data) => {
        let exAdmins = admins.map(item => item.id)
        data.admins = checkedAdmins.concat(exAdmins)
        data.id = editId
        if(changedImg !== null) {
            data.logo = changedImg
        }
        try {
            let res = await axiosInstance.put(`admin/companies/${editId}`, data);
            if (res.status === 201 || res.status === 200) {
                navigate('/super_companies');
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }

    }
    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChangedImg(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(file); // Convert image to base64 format
        }
    }
    const cancelEdit = () => {
        navigate('/super_companies')
    }
    const archiveCompany = () => {
        console.log('archive company')
    }
    return (
        <>
            <div className={styles.page__content}>
                <div className={styles.page__head_container}>
                    <h1 className={styles.page__title}>Edit Company</h1>
                    <div className={styles.page__head_btn__container}>
                        <button className={styles.page__head_btn} onClick={() => archiveCompany()}>Archive Company</button>
                        <button className={styles.page__head_btn__delete} onClick={() => deleteCompany()}>Delete Company</button>
                    </div>
                </div>

                <p className={styles.page__subtitle}>Personal information</p>
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/*block*/}
                    <div className={styles.page__data_block}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__data_sub}>Company logo</p>
                            <div className={styles.page__data_img__container}>

                                <div className={styles.page__data_img__icon}>
                                    <input type="file" accept="image/*" onChange={onFileChange} />
                                </div>
                                    <div className={styles.page__data_img_selected}>
                                        {selectedImage &&
                                            <img
                                                src={selectedImage}
                                                alt="Selected"
                                            />
                                        }
                                        {changedImg &&
                                        <img
                                            src={changedImg}
                                            alt="Selected"
                                            className={styles.page__data_img_changed}
                                        />
                                        }
                                    </div>

                            </div>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Company name</label>
                                    <Input input={{
                                        placeholder: "Company name",
                                        name: "name",
                                        type: "text",
                                        ...register("name", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.name && <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label>Email address</label>
                                    <Input input={{
                                        placeholder: "companyname@gmail.com",
                                        name: "email",
                                        type: "email",
                                        ...register("email", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.email?.message ?
                                        <span className='textDanger'>{errors.email.message}</span> :
                                        errors.email &&
                                        <span className='textDanger'>This field is required</span>}
                                    {/*{errors.email  && <span className='textDanger'>This field is required</span>}*/}
                                </div>
                            </div>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Location</label>
                                    <Input input={{
                                        placeholder: "Street",
                                        name: "location",
                                        type: "text",
                                        ...register("location", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                    <Input input={{
                                        placeholder: "+87 5469 554 78",
                                        name: "phone",
                                        type: "number",
                                        ...register("phone", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*block ADMINS*/}
                    <div className={styles.page__data_block}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__subtitle}>Admin</p>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__admins_container}>
                                <div className={styles.page__admins_cards}>
                                    {admins.map((t, i) => {
                                        return(
                                            <div key={i} className={styles.page__admins_card}>
                                                <div className="">
                                                    <div className={`${styles.page__admins_card__dots}`}
                                                         id="dropdownMenuButton" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                    </div>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <span className="dropdown-item" onClick={() => editAdmin(t)}>Edit</span>
                                                        <span className="dropdown-item" onClick={() => deleteAdmin(t.id)}>Delete</span>
                                                    </div>
                                                </div>
                                                <div className={styles.page__admins_card__img}>
                                                    {t.image &&
                                                    <img
                                                        src={t.image}
                                                        alt={t.first_name}
                                                    />
                                                    }
                                                </div>
                                                <span className={styles.page__admins_card__name}>{t.first_name} &nbsp; {t.last_name}</span>

                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={styles.page__data_select__content}>
                                    <div className={`${styles.page__data_select_item} form-group`}>
                                        <label htmlFor="exampleFormControlSelect2">Select Admins</label>
                                        <select
                                            value={checkedAdmins}
                                            onChange={handleAdmins}
                                            className={`form-select ${styles.page__data_select}`}
                                            multiple
                                            aria-label="multiple select example">
                                            {createdAdmins.map((admin) => {
                                                return(
                                                    <option key={admin.id} value={admin.id}>{admin.first_name}&nbsp;{admin.last_name}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                    <div className={styles.page__admins_btn__container}>
                                        <div className={styles.page__admins_btn}
                                             onClick={() => newAdmin()}
                                        >New admin
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M16 27.4985C15.1369 27.4985 14.4375 26.7991 14.4375 25.936V6.06348C14.4375 5.20035 15.1369 4.50098 16 4.50098C16.8631 4.50098 17.5625 5.20035 17.5625 6.06348V25.936C17.5625 26.7991 16.8631 27.4985 16 27.4985Z" fill="#878790"/>
                                                <path d="M25.937 17.5625H6.06445C5.20133 17.5625 4.50195 16.8631 4.50195 16C4.50195 15.1369 5.20133 14.4375 6.06445 14.4375H25.937C26.8001 14.4375 27.4995 15.1369 27.4995 16C27.4995 16.8631 26.8001 17.5625 25.937 17.5625Z" fill="#878790"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*block*/}
                    <div className={styles.page__data_block}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__subtitle}>Managers</p>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__admins_container}>
                                <div className={styles.page__admins_cards}>
                                    {/*<div className={styles.page__teams_container}>*/}
                                    {/*    {teamsBox.map((t, i) => {*/}
                                    {/*        return(*/}
                                    {/*            <div key={i}>{t.name}*/}
                                    {/*                <div className="form-check-lg form-switch">*/}
                                    {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                                    {/*                           id="flexSwitchCheckDefault" checked={t.inputValue} onChange={(e)=>onChangeTeam(t.id, e)} />*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        )*/}
                                    {/*    })}*/}
                                    {/*</div>*/}
                                </div>
                                <div className={styles.page__admins_btn__container}>
                                    <div className={styles.page__admins_btn}
                                         onClick={() => newManager()}
                                    >New manager
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M16 27.4985C15.1369 27.4985 14.4375 26.7991 14.4375 25.936V6.06348C14.4375 5.20035 15.1369 4.50098 16 4.50098C16.8631 4.50098 17.5625 5.20035 17.5625 6.06348V25.936C17.5625 26.7991 16.8631 27.4985 16 27.4985Z" fill="#878790"/>
                                            <path d="M25.937 17.5625H6.06445C5.20133 17.5625 4.50195 16.8631 4.50195 16C4.50195 15.1369 5.20133 14.4375 6.06445 14.4375H25.937C26.8001 14.4375 27.4995 15.1369 27.4995 16C27.4995 16.8631 26.8001 17.5625 25.937 17.5625Z" fill="#878790"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*block*/}
                    <div className={styles.page__data_block}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__subtitle}>KPI</p>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Turnover</label>
                                    <Input input={{
                                        placeholder: "0",
                                        name: "turnover",
                                        type: "number",
                                        ...register("turnover", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                                <div className={styles.form_group}>
                                    <label>Absenteeism</label>
                                    <Input input={{
                                        placeholder: "0",
                                        name: "absenteeism",
                                        type: "number",
                                        ...register("absenteeism", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                            </div>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Sales</label>
                                    <Input input={{
                                        placeholder: "0",
                                        name: "sales",
                                        type: "number",
                                        ...register("sales", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="exampleFormControlInput1">Margins</label>
                                    <Input input={{
                                        placeholder: "0",
                                        name: "margins",
                                        type: "number",
                                        ...register("margins", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.page__done_container}>
                        <button className={styles.page__cancel_btn} onClick={() => cancelEdit()}>Cancel</button>
                        <button className={styles.page__done_btn} type="submit">Edit</button>
                    </div>

                </form>

                <MigrateModal
                    closeMigrateModal = {closeMigrateModal}
                    migrateModalIsOpen ={migrateModalIsOpen}
                    onSuccess={delCompany}
                    data ={
                        {
                            title: 'Delete company',
                            text: 'Are you sure you want to delete a company?',
                            button_info1: 'No',
                            button_info2: 'Yes',}
                    }
                />
            </div>
        </>
    )


}
