import React from "react";
import {Outlet} from 'react-router-dom'
import {TopHeader} from "../TopHeader/TopHeader"
import {Footer} from "../Footer/Footer";
import styles from './layout.module.scss'
const Layout = () => {
    return(
        <>
            <div>
                {/*<div className='col-2 p-0'>*/}
                {/*    <LeftBar />*/}
                {/*</div>*/}
                <div style={{position: 'relative'}} >
                    <TopHeader />
                    <main className={styles.main__content}>
                        <Outlet />
                    </main>
                    <Footer />

                </div>
            </div>
        </>
    )
}
export default Layout
