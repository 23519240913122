import React from "react";
import styles from "./Footer.module.scss";
export  const Footer = () => {
    return (
        <div className={styles.footer__container}>
            <div className={styles.footer__content}>
                <div className={styles.footer__logo}></div>
                <div className={styles.footer__touch_container}>
                    <div className={styles.footer__touch_follow}>
                        <a target='_blank' rel="noreferrer" href="https://www.youtube.com/" className={styles.footer__touch_youtube}></a>
                        <a target='_blank' rel="noreferrer" href="https://www.linkedin.com/" className={styles.footer__touch_linkedin}></a>
                        <a target='_blank' rel="noreferrer" href="https://x.com/" className={styles.footer__touch_x}></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
