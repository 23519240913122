import React, { useState, useEffect, useRef } from "react";
import styles from "./TopHeader.module.scss";
import { useNavigate, useLocation } from "react-router-dom";

export const TopHeader = () => {
    const [state] = useState(localStorage.getItem("userRole"));
    const navigate = useNavigate();
    const location = useLocation();
    const [messages, setMessages] = useState([]);
    const wsRef = useRef(null);

    const serverUrl = "wss://ws.peopleup.ch:6001/ws"; // WebSocket server URL
    const channel = "superadmin-notifications.1"; // Replace with the channel name
    const token = localStorage.getItem("token"); // Replace with your token

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userRole");
        navigate("/login");
    };

    useEffect(() => {
        const connectWebSocket = () => {
            wsRef.current = new WebSocket(serverUrl);

            wsRef.current.onopen = () => {
                console.log("WebSocket connection established");

                wsRef.current.send(
                    JSON.stringify({
                        action: "subscribe",
                        channel,
                        token,
                    })
                );
            };

            wsRef.current.onmessage = (event) => {
                console.log("Message received from server:", event.data);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    `Server: ${event.data}`, // Corrected message format
                ]);
            };

            wsRef.current.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            wsRef.current.onclose = () => {
                console.log("WebSocket connection closed. Reconnecting...");
            };
        };

        connectWebSocket();

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    return (
        <div className={styles.top_bar}>
            <div className={styles.top_bar__logo}></div>
            {!location.pathname.includes("login") && (
                <div className={styles.top_bar__menu_container}>
                    {state === "superadmin" && (
                        <div className="d-flex">
                            <div className={`${styles.top_bar__dropdown}`}>
                                <button
                                    className="dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Spotlight
                                </button>
                                <ul
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li
                                        className="dropdown-item"
                                        onClick={() => {
                                            navigate("/super_spotlight");
                                        }}
                                    >
                                        Spotlight
                                    </li>
                                </ul>
                            </div>
                            <div className={`${styles.top_bar__dropdown}`}>
                                <button
                                    className="dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Company
                                </button>
                                <ul
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton2"
                                >
                                    <li
                                        className="dropdown-item"
                                        onClick={() => {
                                            navigate("/super_companies");
                                        }}
                                    >
                                        All Companies
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}

                    {/* Additional roles can be added similarly */}
                </div>
            )}

            {!location.pathname.includes("login") && (
                <div className={styles.top_bar__right}>
                    <div className={styles.input__container}>
                        <span className={styles.input__icon}></span>
                        <input type="text" placeholder="Search" />
                    </div>

                    <div className={styles.top_bar__bell_logo}></div>

                    <div>
                        <h5>WebSocket Messages:</h5>
                        <ul>
                            {messages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
